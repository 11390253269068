<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="isUpdate?$t('users.client.update_btn'):$t('users.client.new_btn')"
        :paths="[{title:$t('users.client.title'),link:'users-clients'}]"
    />
    <section class='wameed-dashboard-page-content_body products_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'
               :key="key">
          <b-card no-body class='wameed-card'>
            <b-card-header class="align-items-center tab-title">
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('users.client.info') }}
              </h5>

            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                    class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='4'>
                      <wameed-input-file-upload
                          fileType='images'
                          field-classes='w-100'
                          srcName='url'
                          functionType='front'
                          :value="form.temp_profile_image&&form.temp_profile_image.length>0?form.temp_profile_image[0].url:''"
                          :uploadedFiles='form.temp_profile_image'
                          :label="$t('users.client.form.profile_image')"
                          :input-placeholder="$t('form.image.placeholder')"
                          :input-append-text="$t('form.image.browse')"
                          @changed='uploadProfileImage'
                          @deleteImage='deleteProfileImage'
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <text-input
                          id='name'
                          v-model='form.name'
                          :label="$t('users.client.form.name')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                          v-model='form.gender'
                          :label="$t('common.gender')"
                          :placeholder="$t('common.gender')"
                          variant='disable'
                          :items='clientGenders'
                          class='wameed_dropdown'
                          title='name'
                          rules='required'
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                          v-model='form.category'
                          :label="$t('users.client.form.category')"
                          :placeholder="$t('users.client.form.category')"
                          variant='disable'
                          :items='clientCategory'
                          class='wameed_dropdown'
                          title='name'
                          rules='required'
                          @input="onCategoryChange"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>


                    <b-col cols='12' md='4'>
                      <TextInput
                          v-model='form.email'
                          field-classes='w-100'
                          rules='email'
                          :label="$t('form.email.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type='text'
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                          v-model='form.phone'
                          field-classes='w-100'
                          rules='required'
                          :label="$t('form.phone.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type='number'
                      />
                    </b-col>


                  </div>


                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-white rounded-10'
                          :title="isUpdate?$t('users.client.update_btn'):$t('users.client.new_btn')"
                          :disabled='invalid'
                          type='submit'
                          variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-font-secondary rounded-10 '
                          :title="$t('common.cancel')"
                          type='button'
                          variant='gray'
                          @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <map-modal
        @setLocation='setLocation'
        :visible='showMapModal'
        @close='showMapModal = false'
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('users.client.sent.title')"
        :subTitle="$t('users.client.sent.subTitle')"
        :btnTitle="$t('common.OK')"
    />


  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import {ClientCategory, ClientGenders} from "@/enums/clientCategory.enum";

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import MapModal from '@/components/mapModal';
import WarningModal from '@/components/WarningModal';

export default {
  components: {
    WarningModal,
    MapModal,
    TextInput,
    TextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      key: 1,
      showMapModal: false,
      showSentModal: false,

      clientCategory: ClientCategory,
      clientGenders: ClientGenders,
      form: {
        name: '',
        category: '',
        gender: '',

        email: '',
        phone: '',

        temp_profile_image: [],

      }

    };
  },
  computed: {
    ...mapGetters({
      userDetail: 'admin/users/getDetail'
    }),


    maxOrderTime() {
      let value = this.form.min_order_time;
      if (value) {
        return '|min_value:' + value
      }
      return '';
    },

    tempProfileImageValue() {
      if (this.form.temp_profile_image.length > 0)
        return this.form.temp_profile_image[0].url
      return '';
    },


    isUpdate() {
      return (this.$route.name === 'users-clients-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {

    if (this.$route.name === 'users-clients-update' && this.$route.params.id) {
      this.$store.dispatch('admin/users/simpleDetail', {id: this.$route.params.id, type: 'clients'}).then(async () => {

        await this.$store.dispatch('admin/users/getVendorClassification', this.userDetail.category_id);

        this.form = this.userDetail;
        if (this.userDetail.profile_image)
          this.form.temp_profile_image = [{url: this.userDetail.profile_image}];
        if (this.userDetail.profile_bg)

          this.form.category = this.clientCategory.find((cat) => cat.id === this.userDetail.category_id);
      });

    } else {
      await this.$store.dispatch('admin/users/unsetDetail');
    }
  },


  methods: {
    uploadProfileImage(value) {
      this.key++;
      this.form.temp_profile_image = [value];
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_profile_image = [];
    },


    goBack() {
      this.$router.push({name: 'users-clients'});
    },

    async onCategoryChange() {
      this.form.classifications = [];
      // await this.$store.dispatch('admin/users/getVendorClassification', this.form.category.id);

    },

    submit() {


      this.form.category_id = this.form.category.id;
      this.form.gender_id = this.form.gender.id;
      this.form.type = 'clients';
      this.form.profile_bg = null;
      this.form.profile_image = null;


      if (this.form.temp_profile_image) {
        this.form.profile_image = this.form.temp_profile_image[0];
      }

      if (this.form.classifications) {
        this.form.classifications_ids = this.form.classifications.map(a => a.id);
      }

      if (this.$route.name === 'users-clients-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/users/update', this.form);
      } else {
        this.$store.dispatch('admin/users/create', this.form).then(() => {
          this.showSentModal = true;
        });
      }

    }
  }

}
;
</script>
