import i18n from "@/libs/i18n";




const ClientCategory = [
    {
        id: 1,
        name: i18n.t('users.client.individual'),
    },
    {
        id: 2,
        name: i18n.t('users.client.company'),
    },


];



const ClientGenders = [
    {
        id: 0,
        name: i18n.t('users.client.female'),
    },
    {
        id: 1,
        name: i18n.t('users.client.male'),
    },


];

function filterClientCategory(id) {
    return ClientCategory.find((item) => item.id === id);
}

export {ClientCategory, filterClientCategory,ClientGenders};
